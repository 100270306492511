import { Column } from './table/table.component';

export class TableUtils {
  public static initFilters(columns: Column[]) {
    let filters = {};
    for (let column of columns)
      filters[column.field] = null;

    return filters;
  }
}
