import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthorizeService, IUser } from '../../api-authorization/authorize.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {

  public isAuthenticated: Observable<boolean>;
  public userName: Observable<string>;
  mustLogIn: any;

  constructor(private authorizeService: AuthorizeService) {
  }

  ngOnInit(): void {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    this.userName = this.authorizeService.getUser().pipe(map(u => u && u.name));
  }
}
