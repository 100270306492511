import {
  Component,
  OnInit,
  ViewEncapsulation
}                               from '@angular/core';
import {
  BehaviorSubject,
  of
}                               from 'rxjs';
import {
  TableEventData,
  Column
}                               from '../../table/table/table.component';
import {
  Order,
  OrdersApi,
  Orders,
  Status
}                               from './orders-api';
import {
  MatDialog,
  MatDialogConfig
}                               from '@angular/material';
import { Types }                from '../../table/filter/filter.component';
import { TableUtils }           from '../../table/table-utils';
import {
  tap,
  catchError,
  switchMap,
  filter
}                               from 'rxjs/operators';
import { HttpErrorResponse }    from '@angular/common/http';
import { EditOrderComponent }   from './edit-order/edit-order.component';
import { CancelOrderComponent } from './cancel-order/cancel-order.component';

@Component({
  selector:      'app-supply-orders',
  templateUrl:   './supply-orders.component.html',
  styleUrls:     ['./supply-orders.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SupplyOrdersComponent implements OnInit {

  orders: BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>([]);
  rowCount: number;
  filtersAndOrdering: TableEventData;

  loading: boolean = false;

  columns = new BehaviorSubject<Column[]>([
    {
      index:       0,
      field:       'id',
      headerLabel: 'id',
      type:        Types.Text
    },
    {
      index:       1,
      field:       'client',
      headerLabel: 'Client',
      type:        Types.Text
    },
    {
      index:       2,
      field:       'location',
      headerLabel: 'Location',
      type:        Types.Text
    },
    {
      index:       3,
      field:       'creator',
      headerLabel: 'User',
      type:        Types.Text
    },
    {
      index:       4,
      field:       'created',
      headerLabel: 'Requested date',
      type:        Types.Date
    },
    {
      index:       5,
      field:       'product',
      headerLabel: 'Product',
      type:        Types.Text
    },
    {
      index:       6,
      field:       'expectedDelivery',
      headerLabel: 'Expected delivery',
      type:        Types.Date
    },
    {
      index:       7,
      field:       'quantity',
      headerLabel: 'Quantity',
      type:        Types.Number
    },
    {
      index:       8,
      field:       'status',
      headerLabel: 'Status',
      type:        Types.Text
    },
    {
      index:       9,
      field:       'actions',
      headerLabel: 'Actions',
      type:        null
    }
  ]);

  defaultOrederBy: string = "created";
  defaultDirection: string = "desc";

  constructor(private api: OrdersApi, private dialog: MatDialog) {

    this.filtersAndOrdering = {
      filters:            TableUtils.initFilters(this.columns.value),
      orderAndPagination: {
        order:      {
          direction: this.defaultDirection,
          orderBy:   this.defaultOrederBy
        },
        pagination: {
          pageNumber: 0,
          pageSize:   10
        }
      }
    };
    this.loading = true;
    this.api.getOrders(this.filtersAndOrdering)
      .subscribe((x: Orders) => {
        this.orders.next(x.orders);
        this.rowCount = x.count;
        this.loading = false;
      });
  }

  ngOnInit() {
  }

  manageTableEvent(event: TableEventData) {
    this.loading = true;
    this.filtersAndOrdering = event;
    this.api.getOrders(this.filtersAndOrdering)
      .subscribe((x: Orders) => {
        this.orders.next(x.orders);
        this.rowCount = x.count;
        this.loading = false;
      });
  }

  editOrder(o: Order) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      order: o
    };
    dialogConfig.width = '55vw';
    const dialogRef = this.dialog.open(EditOrderComponent, dialogConfig);
    dialogRef.afterClosed()
      .pipe(
        filter((order: Order) => order != null),
        tap(() => this.loading = true),
        switchMap((updatedOrder: Order) => this.api.editOrder(updatedOrder)),
        catchError((x: HttpErrorResponse) => {
          alert('❌');
          this.loading = false;
          return of(0);
        }),
        switchMap(() => this.api.getOrders(this.filtersAndOrdering))
      )
      .subscribe((x: Orders) => {
        this.orders.next(x.orders);
        this.rowCount = x.count;
        this.loading = false;
      });
  }

  deleteOrder(o: Order) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      order: o
    };
    const dialogRef = this.dialog.open(CancelOrderComponent, dialogConfig);
    dialogRef.afterClosed()
      .pipe(
        filter((cancelConfirmed: Boolean) => cancelConfirmed == true),
        tap(() => {
          o.status = Status.annullato;
          this.loading = true;
        }),
        switchMap(x => this.api.editOrder(o)),
        catchError((x: HttpErrorResponse) => {
          alert('❌');
          this.loading = false;
          return of(0);
        }),
        switchMap(() => this.api.getOrders(this.filtersAndOrdering))
      )
      .subscribe((x: Orders) => {
        this.orders.next(x.orders);
        this.rowCount = x.count;
        this.loading = false;
      });
  }

  exportExcel(): void {
    this.api.exportExcel()
      .subscribe((file: Blob) => {
        var blob = new Blob([file], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Orders.xlsx';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      });
  }

}
