import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Box, BoxesApi, IdCode } from '../boxes-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-upsert-box',
  templateUrl: './upsert-box.component.html',
  styleUrls: ['./upsert-box.component.css']
})
export class UpsertBoxComponent implements OnInit {

  title: string;

  locations: BehaviorSubject<IdCode[]> = new BehaviorSubject<IdCode[]>([]);
  products: BehaviorSubject<IdCode[]> = new BehaviorSubject<IdCode[]>([]);

  productCode: FormControl;
  locationCode: FormControl;
  maximumSupply: FormControl;
  resupplyQuantity: FormControl;

  constructor(public dialogRef: MatDialogRef<UpsertBoxComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    let box: Box = data.update ? data.box : {
      locationCode: null,
      locationId: null,
      productCode: null,
      productId: null,
      maximumSupply: null,
      resupplyQuantity: null
    };

    this.title = data.update ? "Edit box" : "Create new box";
    this.productCode = new FormControl(box.productCode, [Validators.required]);
    this.locationCode = new FormControl(box.locationCode, [Validators.required]);
    this.maximumSupply = new FormControl(box.maximumSupply, [Validators.required]);
    this.resupplyQuantity = new FormControl(box.resupplyQuantity, [Validators.required]);

    this.locations.next(data.locations);
    this.locationCode.valueChanges
      .subscribe(newValue => {
        let filtered = this.data.locations.filter(x => x.code.toLowerCase().indexOf(newValue.toLowerCase()) >= 0);
        this.locations.next(filtered);
      });

    this.products.next(data.products);
    this.productCode.valueChanges
      .subscribe(newValue => {
        let filtered = this.data.products.filter(x => x.code.toLowerCase().indexOf(newValue.toLowerCase()) >= 0);
        this.products.next(filtered);
      });

  }

  ngOnInit() {
  }

  close(save: boolean) {

    let result: Box = save ? {
      locationCode: this.locationCode.value,
      locationId: this.locations.value.find(x => x.code==this.locationCode.value).id,
      productCode: this.productCode.value,
      productId: this.products.value.find(x => x.code==this.productCode.value).id,
      maximumSupply: this.maximumSupply.value,
      resupplyQuantity: this.resupplyQuantity.value
    } : null;

    this.dialogRef.close(result);
  }

}
