import {
  Component,
  OnInit,
  Inject,
  AfterViewInit
} from '@angular/core';
import {
  Product,
  ProductsApi
} from '../products-api';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material';
import {
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn
} from '@angular/forms';

@Component({
  selector: 'app-upsert-product',
  templateUrl: './upsert-product.component.html',
  styleUrls: ['./upsert-product.component.css']
})
export class UpsertProductComponent implements OnInit {

  title: string;
  productId: number;
  codeList: string[];

  code: FormControl;
  description: FormControl;
  supplier: FormControl;
  managementKind: FormControl;
  buyer: FormControl;
  image: FormControl;
  selectedImg: any;

  //imagePreview: string | ArrayBuffer;
  imagePreview: any;

  constructor(public dialogRef: MatDialogRef<UpsertProductComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private api: ProductsApi) {
    let product: Product = data.update ? data.product : {
      buyer: null,
      code: null,
      description: null,
      id: null,
      imageName: null,
      managementKind: null,
      supplier: null,
      image: null
    };
    this.title = data.update ? 'Edit product' : 'Create new product';

    this.codeList = data.codeList;

    this.productId = product.id;
    this.code = new FormControl(product.code, [
      Validators.required,
      usedCodeValidator(this.codeList, product.code)
    ]);
    this.description = new FormControl(product.description, [Validators.required]);
    this.supplier = new FormControl(product.supplier, [Validators.required]);
    this.managementKind = new FormControl(product.managementKind, [Validators.required]);
    this.buyer = new FormControl(product.buyer, [Validators.required]);
    this.image = new FormControl(null, [Validators.required]);
    this.imagePreview =  product.imageName == null ? null : '/api/Products/getProductImage/' + product.imageName;
  }

  ngOnInit() {
  }

  uploadImage(event) {
    let file = event.target.files[0];
    var fileReader = new FileReader();

    fileReader.onload = (readerEvent: any) => {
      let image = new Image();

      image.onload = imageEvent => {
        let canvas = document.createElement('canvas');
        let maxSize = 500;
        let width = image.width;
        let height = image.height;
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        this.imagePreview = canvas.toDataURL('image/jpeg');
        this.image.setValue(this.dataURLToBlob(this.imagePreview));
      }

      image.src = readerEvent.target.result;
    }

    fileReader.readAsDataURL(file);
  }

  dataURLToBlob(dataURL) {
    let BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      let parts = dataURL.split(',');
      let contentType = parts[0].split(':')[1];
      let raw = parts[1];

      return new Blob([raw], { type: contentType });
    }

    let parts = dataURL.split(BASE64_MARKER);
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;

    let uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  close(save: boolean) {

    let result: Product = save ? {
      buyer: this.buyer.value,
      code: this.code.value,
      description: this.description.value,
      id: this.productId,
      imageName: this.image.value ? this.image.value.name : null,
      image: this.image.value ? this.image.value : null,
      managementKind: this.managementKind.value,
      supplier: this.supplier.value
    } : null;

    this.dialogRef.close(result);
  }

}

export function usedCodeValidator(usedCodes: string[], current: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = usedCodes.indexOf(control.value) < 0 || current == control.value;
    return valid ? null : { 'usedCode': { value: control.value } };
  };
}
