import { Component, OnInit, Inject } from '@angular/core';
import { Box } from '../boxes-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-delete-box',
  templateUrl: './delete-box.component.html',
  styleUrls: ['./delete-box.component.css']
})
export class DeleteBoxComponent implements OnInit {

  box: Box;

  constructor(public dialogRef: MatDialogRef<DeleteBoxComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.box = data.box;
  }

  ngOnInit() {
  }

  close(save: boolean) {
    this.dialogRef.close(save);
  }

}
