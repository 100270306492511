import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { Location } from '../locations-api';
import { Client } from '../../../commons/ClientsApi/clients-api';

@Component({
  selector: 'app-upsert-location',
  templateUrl: './upsert-location.component.html',
  styleUrls: ['./upsert-location.component.css']
})
export class UpsertLocationComponent implements OnInit {

  title: string;
  locationId: number;
  locationCode: string;
  clients: Client[];

  area: FormControl;
  bay: FormControl;
  client: FormControl;
  description: FormControl;
  floor: FormControl;
  lane: FormControl;
  position: FormControl;
  shelf: FormControl;
  warehouse: FormControl;

  constructor(public dialogRef: MatDialogRef<UpsertLocationComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.clients = data.clients;
    let location : Location = data.update ? data.location : {
      area: null,
      bay: null,
      client: null,
      clientId: null,
      description: null,
      floor: null,
      id: null,
      lane: null,
      position: null,
      shelf: null,
      warehouse: null,
      code: null
    };
    this.title = data.update ? "Edit location" : "Create new location";

    this.locationId = location.id;
    this.locationCode = location.locationCode;
    this.area = new FormControl(location.area, [Validators.required]);
    this.bay = new FormControl(location.bay);
    this.client = new FormControl(this.clients.find(x => x.id == location.clientId), [Validators.required]);
    this.description = new FormControl(location.description, [Validators.required]);
    this.floor = new FormControl(location.floor);
    this.lane = new FormControl(location.lane);
    this.position = new FormControl(location.position, [Validators.required]);
    this.shelf = new FormControl(location.shelf);
    this.warehouse = new FormControl(location.warehouse, [Validators.required]);
    this.description = new FormControl(location.description, [Validators.required]);
  }

  ngOnInit() {
  }

  close(save: boolean) {

    let result: Location = save ? {
      area: this.area.value,
      bay: this.bay.value,
      client: this.client.value.name,
      clientId: this.client.value.id,
      description: this.description.value,
      floor: this.floor.value,
      id: this.locationId,
      lane: this.lane.value,
      position: this.position.value,
      shelf: this.shelf.value,
      warehouse: this.warehouse.value,
      locationCode: this.locationCode
    } : null;

    this.dialogRef.close(result);
  }

}
