import { Component }      from '@angular/core';
import {
  Router
}                         from '@angular/router';
import {
  AuthorizeService,
}                         from '../../api-authorization/authorize.service';
import { ToolbarService } from './toolbar.service';

@Component({
  selector:    'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls:   ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;
  isAuthenticated: boolean = false;

  constructor(
    private router: Router,
    private authorizeService: AuthorizeService,
    public toser: ToolbarService
  ) {
    authorizeService.isAuthenticated()
      .subscribe((x: boolean) => this.isAuthenticated = x);
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  go(dest: string[]) {
    this.router.navigate(dest);
  }
}
