import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private router: Router, private titleService: Title) {
    this.titleService.setTitle("E-KANBAN");
  }

  go(destination: string[]) {
    this.router.navigate(destination);
  }
}
