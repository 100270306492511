import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Location } from '../locations-api';

@Component({
  selector: 'app-delete-location',
  templateUrl: './delete-location.component.html',
  styleUrls: ['./delete-location.component.css']
})
export class DeleteLocationComponent implements OnInit {

  location: Location

  constructor(public dialogRef: MatDialogRef<DeleteLocationComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.location = data.location;
  }

  ngOnInit() {
  }

  close(save: boolean) {
    this.dialogRef.close(save);
  }

}
