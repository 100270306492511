import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { TableEventData } from "../../table/table/table.component";
import { Injectable } from "@angular/core";

@Injectable()
export class BoxesApi {
  private readonly requestUrl: string = "/api/Boxes";
  constructor(private http: HttpClient) {}

  getBoxes(filtersAndOrdering: TableEventData): Observable<Boxes> {
    return this.http.post<Boxes>(
      `${this.requestUrl}/GetBoxes`,
      filtersAndOrdering
    );
  }

  GetProductsAndLocations(): Observable<LocationsAndProducts> {
    return this.http.get<LocationsAndProducts>(
      `${this.requestUrl}/GetProductsAndLocations`
    );
  }

  deleteBox(locationId: number, productId: number): Observable<string> {
    return this.http.delete<string>(
      `${this.requestUrl}/${locationId}/${productId}`
    );
  }

  upsertBox(box: Box): Observable<string> {
    return this.http.post<string>(`${this.requestUrl}`, box);
  }

  exportExcel(): Observable<Blob> {
    return this.http.get<Blob>(`${this.requestUrl}/ExportExcel`, {
      responseType: "blob" as "json",
    });
  }
}

export interface Boxes {
  boxes: Box[];
  count: number;
}

export interface Box {
  productId: number;
  locationId: number;
  maximumSupply: number;
  resupplyQuantity: number;
  productCode: string;
  locationCode: string;
}

export interface LocationsAndProducts {
  locations: IdCode[];
  products: IdCode[];
}

export interface IdCode {
  id: number;
  code: string;
}
