import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
@Injectable()
export class ClientsApi {

  private readonly requestUrl: string = '/api/Clients';
  constructor(private http: HttpClient) { }

  getClients(): Observable<Client[]> {
    return this.http.get<Client[]>(`${this.requestUrl}`);
  }
}

export interface Client {
  id: number;
  code: string;
  name: string;
}
