import { Component, OnInit, Input, Renderer2, AfterViewInit, ViewChild, ElementRef, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TableUtils } from '../table-utils';

@Component({
  selector: 'Table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableComponent implements OnInit, AfterViewInit {

  @Input() columns: BehaviorSubject<Column[]>;
  @Input() rows: BehaviorSubject<any[]>;
  @Input() tableLength: number;
  @Input() extraActionIcon: string;
  @Input() canAdd: boolean = true;
  @Input() canEdit: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() defaultOrderValue: string = null;
  @Input() defaultOrderDirectionValue: string = null;

  displayedColumns: string[];

  @ViewChild(MatPaginator, { read: MatPaginator, static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { read: MatSort, static: false }) sort: MatSort;
  @ViewChild('matTable', { read: ElementRef, static: false }) matTableRef: ElementRef;

  tableEventData: TableEventData;

  @Output() tableEvent$: EventEmitter<TableEventData>;
  @Output() addRow$: EventEmitter<null>;
  @Output() editRow$: EventEmitter<any>;
  @Output() deleteRow$: EventEmitter<any>;
  @Output() typeExtraAction$: EventEmitter<any>;

  private filterChanged$: EventEmitter<void>;

  constructor(private renderer: Renderer2) {
    this.tableEvent$ = new EventEmitter<TableEventData>();

    this.addRow$ = new EventEmitter<null>();
    this.editRow$ = new EventEmitter<any>();
    this.deleteRow$ = new EventEmitter<any>();
    this.typeExtraAction$ = new EventEmitter<any>();

    this.filterChanged$ = new EventEmitter<void>();
  }

  ngOnInit() {
    this.columns
      .pipe(
        filter(x => x != null && x != undefined)
      )
      .subscribe((columns: Column[]) => {
        this.displayedColumns = [];
        columns.forEach(c => {
          this.displayedColumns.push(c.field); //default tutte le colonne visualizzate
        });

        this.defaultOrderValue = this.defaultOrderValue == null ? columns[0].field : this.defaultOrderValue;
        this.defaultOrderDirectionValue = this.defaultOrderDirectionValue == null ? "asc" : this.defaultOrderDirectionValue;
        this.tableEventData = {
          filters: TableUtils.initFilters(this.columns.value),
          orderAndPagination: {
            order: {
              //direction: "asc",
              direction: this.defaultOrderDirectionValue,
              //orderBy: columns[0].field
              orderBy: this.defaultOrderValue
            },
            pagination: {
              pageNumber: 0,
              pageSize: 10
            }
          }
        }
      })
  }

  ngAfterViewInit(): void {
    //this.initResizer();

    this.sort.sortChange
      .subscribe((sortEvent) => {
        this.tableEventData.orderAndPagination.order.orderBy = sortEvent.active;
        this.tableEventData.orderAndPagination.order.direction = sortEvent.direction;
        this.tableEvent$.emit(this.tableEventData);
      });

    this.paginator.page
      .subscribe((pageEvent: PageEvent) => {
        this.tableEventData.orderAndPagination.pagination.pageNumber = pageEvent.pageIndex;
        this.tableEventData.orderAndPagination.pagination.pageSize = pageEvent.pageSize;
        this.tableEvent$.emit(this.tableEventData);
      });

    this.filterChanged$
      .subscribe(() => {
        //this.tableEventData.filters = this.getFilters();
        this.tableEvent$.emit(this.tableEventData);
      });
  }

  valueChanged(field: string, event) {
    this.tableEventData.filters[field] = event;
    this.filterChanged$.emit();
  }
}

export interface Column {
  index: number;
  field: string;
  type: string;
  headerLabel: string;
}

export interface TableEventData {
  filters: any;
  orderAndPagination: OrderAndPagination;
}

export interface OrderAndPagination {
  pagination: Pagination;
  order: Order;
}

export interface Pagination {
  pageNumber: number;
  pageSize: number;
}

export interface Order {
  orderBy: string;
  direction: string;
}
