import { Component, OnInit } from "@angular/core";
import { ProductsApi, Product, Products } from "./products-api";
import { BehaviorSubject, of } from "rxjs";
import { Column, TableEventData } from "../../table/table/table.component";
import { Types } from "../../table/filter/filter.component";
import { TableUtils } from "../../table/table-utils";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { DeleteProductComponent } from "./delete-product/delete-product.component";
import { filter, switchMap, catchError, tap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { UpsertProductComponent } from "./upsert-product/upsert-product.component";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.css"],
})
export class ProductsComponent implements OnInit {
  products: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);
  rowCount: number;
  filtersAndOrdering: TableEventData;

  loading: boolean = false;

  columns = new BehaviorSubject<Column[]>([
    {
      index: 0,
      field: "code",
      headerLabel: "Code",
      type: Types.Text,
    },
    {
      index: 1,
      field: "description",
      headerLabel: "Description",
      type: Types.Text,
    },
    {
      index: 2,
      field: "supplier",
      headerLabel: "Supplier",
      type: Types.Text,
    },
    {
      index: 3,
      field: "managementKind",
      headerLabel: "Management kind",
      type: Types.Text,
    },
    {
      index: 4,
      field: "buyer",
      headerLabel: "Buyer",
      type: Types.Text,
    },
    {
      index: 5,
      field: "imageName",
      headerLabel: "Image",
      type: Types.Image,
    },
    {
      index: 6,
      field: "actions",
      headerLabel: "Actions",
      type: null,
    },
  ]);

  defaultOrederBy: string = "code";
  defaultDirection: string = "asc";

  constructor(private api: ProductsApi, private dialog: MatDialog) {
    this.filtersAndOrdering = {
      filters: TableUtils.initFilters(this.columns.value),
      orderAndPagination: {
        order: {
          direction: this.defaultDirection,
          orderBy: this.defaultOrederBy,
        },
        pagination: {
          pageNumber: 0,
          pageSize: 10,
        },
      },
    };
    this.loading = true;
    this.api.getProducts(this.filtersAndOrdering).subscribe((x: Products) => {
      this.products.next(x.products);
      this.rowCount = x.count;
      this.loading = false;
    });
  }

  ngOnInit() {}

  manageTableEvent(event: TableEventData) {
    this.loading = true;
    this.filtersAndOrdering = event;
    this.api.getProducts(this.filtersAndOrdering).subscribe((x: Products) => {
      this.products.next(x.products);
      this.rowCount = x.count;
      this.loading = false;
    });
  }

  upsertProduct(p: Product = null) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      product: p,
      update: p != null,
      codeList: this.products.value.map((x) => x.code),
    };
    dialogConfig.width = "55vw";
    const dialogRef = this.dialog.open(UpsertProductComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(
        filter((product: Product) => product != null),
        tap(() => (this.loading = true)),
        switchMap((updatedProduct: Product) =>
          this.api.upsertProduct(updatedProduct)
        ),
        catchError((x: HttpErrorResponse) => {
          alert("❌");
          this.loading = false;
          return of(0);
        }),
        switchMap(() => this.api.getProducts(this.filtersAndOrdering))
      )
      .subscribe((x: Products) => {
        this.products.next(x.products);
        this.rowCount = x.count;
        this.loading = false;
      });
  }

  deleteProduct(p: Product) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      product: p,
    };
    const dialogRef = this.dialog.open(DeleteProductComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(
        filter((deleteConfirmed: Boolean) => deleteConfirmed == true),
        tap(() => (this.loading = true)),
        switchMap((x) => this.api.deleteProduct(p.id)),
        catchError((x: HttpErrorResponse) => {
          alert("❌");
          this.loading = false;
          return of(0);
        }),
        switchMap(() => this.api.getProducts(this.filtersAndOrdering))
      )
      .subscribe((x: Products) => {
        this.products.next(x.products);
        this.rowCount = x.count;
        this.loading = false;
      });
  }

  extraAction(p: Product) {
    window.open("api/products/GetBarcode/" + p.id, "_blank");
  }

  exportExcel(): void {
    this.api.exportExcel().subscribe((file: Blob) => {
      var blob = new Blob([file], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Products.xlsx";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    });
  }
}
