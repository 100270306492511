import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { TableEventData } from "../../table/table/table.component";

@Injectable()
export class ProductsApi {
  private readonly requestUrl: string = "/api/Products";

  constructor(private http: HttpClient) {}

  getProducts(filtersAndOrdering: TableEventData): Observable<Products> {
    return this.http.post<Products>(
      `${this.requestUrl}/GetProducts`,
      filtersAndOrdering
    );
  }

  deleteProduct(productId: number): Observable<string> {
    return this.http.delete<string>(`${this.requestUrl}/${productId}`);
  }

  upsertProduct(product: Product): Observable<string> {
    try {
      let formData = new FormData();
      formData.append("id", product.id == null ? null : product.id.toString());
      formData.append("code", product.code);
      formData.append("description", product.description);
      formData.append("supplier", product.supplier);
      formData.append("managementKind", product.managementKind);
      formData.append("buyer", product.buyer);
      if (product.image)
        formData.append("image", product.image, product.imageName);

      return this.http.post<string>(`${this.requestUrl}`, formData);
    } catch (e) {
      console.log(e);
    }
  }

  exportExcel(): Observable<Blob> {
    return this.http.get<Blob>(`${this.requestUrl}/ExportExcel`, {
      responseType: "blob" as "json",
    });
  }
}

export interface Products {
  products: Product[];
  count: number;
}

export interface Product {
  id: number;
  code: string;
  description: string;
  supplier: string;
  managementKind: string;
  buyer: string;
  imageName: string;
  image: any;
}
