import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { TableEventData } from "../../table/table/table.component";

@Injectable()
export class OrdersApi {
  private readonly requestUrl: string = "/api/Orders";

  constructor(private http: HttpClient) {}

  getOrders(filtersAndOrdering: TableEventData): Observable<Orders> {
    return this.http.post<Orders>(
      `${this.requestUrl}/GetOrders`,
      filtersAndOrdering
    );
  }

  getOrderLogs(orderId: number): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.requestUrl}/GetOrderLogs/${orderId}`
    );
  }

  editOrder(order: Order): Observable<string> {
    return this.http.post<string>(`${this.requestUrl}`, order);
  }

  exportExcel(status: number = null): Observable<Blob> {
    return this.http.get<Blob>(`${this.requestUrl}/ExportExcel/${status}`, {
      responseType: "blob" as "json",
    });
  }
}

export interface Orders {
  orders: Order[];
  count: number;
}

export interface Order {
  creator: string;
  client: string;
  location: string;
  product: string;
  created: Date;
  quantity: number;
  expectedDelivery: Date;
  status: string;
  id: number;
}

export enum Status {
  inCorso = "In_Corso",
  inserito = "Inserito",
  annullato = "Annullato",
  evaso = "Evaso",
  evasoParziale = "Evaso_Parziale",
}
