import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent }                  from './app.component';
import { NavMenuComponent }              from './nav-menu/nav-menu.component';
import { HomeComponent }                 from './home/home.component';
import { ApiAuthorizationModule }        from 'src/api-authorization/api-authorization.module';
import { AuthorizeGuard }                from 'src/api-authorization/authorize.guard';
import { AuthorizeInterceptor }          from 'src/api-authorization/authorize.interceptor';
import { ProductsComponent }             from './registries/products/products.component';
import { LocationsComponent }            from './registries/locations/locations.component';
import { ProductsApi }                   from './registries/products/products-api';
import { LocationsApi }                  from './registries/locations/locations-api';
import { BrowserAnimationsModule }       from '@angular/platform-browser/animations';
import { SupplyOrdersEmbedeedComponent } from './registries/supply-orders/supply-orders-embedeed/supply-orders-embedeed.component';
import { TableModule }                   from './table/table.module';
import { MaterialModule }                from './material.module';
import { DeleteProductComponent }        from './registries/products/delete-product/delete-product.component';
import { UpsertProductComponent }        from './registries/products/upsert-product/upsert-product.component';
import { DeleteLocationComponent }       from './registries/locations/delete-location/delete-location.component';
import { UpsertLocationComponent }       from './registries/locations/upsert-location/upsert-location.component';
import { ClientsApi }                    from './commons/ClientsApi/clients-api';
import { BoxesComponent }                from './registries/boxes/boxes.component';
import { UpsertBoxComponent }            from './registries/boxes/upsert-box/upsert-box.component';
import { DeleteBoxComponent }            from './registries/boxes/delete-box/delete-box.component';
import { BoxesApi } from './registries/boxes/boxes-api';
import { SupplyOrdersComponent } from './registries/supply-orders/supply-orders.component';
import { EditOrderComponent } from './registries/supply-orders/edit-order/edit-order.component';
import { OrdersApi } from './registries/supply-orders/orders-api';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { CancelOrderComponent } from './registries/supply-orders/cancel-order/cancel-order.component';
registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    ProductsComponent,
    LocationsComponent,
    DeleteProductComponent,
    UpsertProductComponent,
    DeleteLocationComponent,
    UpsertLocationComponent,
    BoxesComponent,
    UpsertBoxComponent,
    DeleteBoxComponent,
    SupplyOrdersComponent,
    SupplyOrdersEmbedeedComponent,
    EditOrderComponent,
    CancelOrderComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ApiAuthorizationModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full',canActivate: [AuthorizeGuard] },
      { path: 'products', component: ProductsComponent,canActivate: [AuthorizeGuard] },
      { path: 'boxes', component: BoxesComponent,canActivate: [AuthorizeGuard] },
      { path: 'locations', component: LocationsComponent,canActivate: [AuthorizeGuard] },
      { path: 'supplyOrders', component: SupplyOrdersComponent,canActivate: [AuthorizeGuard] },
      { path: 'supplyOrders'+ '/:' + 'client', component: SupplyOrdersEmbedeedComponent, }
    ]),
    BrowserAnimationsModule,
    TableModule,
    MaterialModule
  ],
  providers: [
    ProductsApi,
    LocationsApi,
    ClientsApi,
    BoxesApi,
    OrdersApi,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    [{ provide: LOCALE_ID, useValue: 'it' }]
  ],
  entryComponents: [
    DeleteProductComponent,
    UpsertProductComponent,
    DeleteLocationComponent,
    UpsertLocationComponent,
    DeleteBoxComponent,
    UpsertBoxComponent,
    CancelOrderComponent,
    EditOrderComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
