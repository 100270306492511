import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() type: string;

  value: FormControl;

  @Output() valueChanged$: EventEmitter<any>;

  constructor() {
      this.value = new FormControl(null);
      this.valueChanged$ = new EventEmitter<any>();
  }

  ngOnInit() {}

  emits() {
     this.valueChanged$.emit(this.value.value); 
  }

}

export enum Types {
    Text = "text",
    Number = "number",
    Date = "date",
    Image = "image"
}
