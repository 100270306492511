import {
  Component,
  OnInit,
  Inject
}                from '@angular/core';
import { Order } from '../orders-api';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
}                from '@angular/material';

@Component({
  selector:    'app-cancel-order',
  templateUrl: './cancel-order.component.html',
  styleUrls:   ['./cancel-order.component.css']
})
export class CancelOrderComponent implements OnInit {

  order: Order;

  constructor(public dialogRef: MatDialogRef<CancelOrderComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.order = data.order;
  }

  ngOnInit() {
  }

  close(save: boolean) {
    this.dialogRef.close(save);
  }
}
