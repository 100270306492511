import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Product } from '../products-api';

@Component({
  selector: 'app-delete-product',
  templateUrl: './delete-product.component.html',
  styleUrls: ['./delete-product.component.css']
})
export class DeleteProductComponent implements OnInit {

  product: Product

  constructor(public dialogRef: MatDialogRef<DeleteProductComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.product = data.product;
  }

  ngOnInit() {
  }

  close(save: boolean) {
    this.dialogRef.close(save);
  }

}
