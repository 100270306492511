import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormControl, Validators } from "@angular/forms";
import { ProductsApi } from "../../products/products-api";
import { Order, Status, OrdersApi } from "../orders-api";

@Component({
  selector: "app-edit-order",
  templateUrl: "./edit-order.component.html",
  styleUrls: ["./edit-order.component.css"],
})
export class EditOrderComponent implements OnInit {
  order: Order;
  statusList: string[];
  logs: string[];

  status: FormControl;
  expectedDelivery: FormControl;
  quantity: FormControl;
  partialQuantity: FormControl;

  error: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EditOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: OrdersApi
  ) {
    this.order = data.order;
    this.status = new FormControl(this.order.status, [Validators.required]);
    this.expectedDelivery = new FormControl(this.order.expectedDelivery);
    this.quantity = new FormControl(this.order.quantity, [Validators.required]);
    this.partialQuantity = new FormControl(1, [Validators.required]);

    this.statusList = [];
    for (let s in Status) {
      if (Status[s] != "In_Corso") this.statusList.push(Status[s]);
    }

    this.api
      .getOrderLogs(this.order.id)
      .subscribe((x: string[]) => (this.logs = x));

    this.partialQuantity.valueChanges.subscribe((x) => {
      if (x > this.quantity.value) {
        this.partialQuantity.setErrors({ greaterThan: true });
      } else this.partialQuantity.setErrors(null);
    });
  }

  ngOnInit() {}

  close(save: boolean) {
    let result: Order = save
      ? {
          status:
            this.status.value != "Evaso_Parziale" ? this.status.value : "Evaso",
          expectedDelivery: this.expectedDelivery.value,
          quantity:
            this.status.value != "Evaso_Parziale"
              ? this.quantity.value
              : this.partialQuantity.value,
          client: this.order.client,
          created: this.order.created,
          creator: this.order.creator,
          id: this.order.id,
          location: this.order.location,
          product: this.order.product,
        }
      : null;

    this.dialogRef.close(result);
  }
}
